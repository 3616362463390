import { IProduct } from './../../../interfaces/index';
import { apigClient } from "../aws";
import { mapProductResponseToProduct } from '../../../mappers/product';


export const getProductByModelCode = async (modelCode: string): Promise<IProduct[]> => {
    const client = await apigClient();
    const path = `/products/v4/byModelCode/${modelCode}`;
    const method = 'GET';
    const result = await client.invokeApi({}, path, method, {});
    return result.data.productOfferings?.map(mapProductResponseToProduct);
}