import { AppointmentType, ChatPopupType } from '../../../../../interfaces';
import { actionStartFetchingCatalog } from '../../../../../redux/customActions/loubiAirways';

const louboutinJpWebsite = 'https://jp.christianlouboutin.com/jp_ja/';

export default {
  chatbotName: 'Louboutin Japan',
  messages: [
    {
      id: 1,
      content: 'Welcome to the Loubiairways Trunkshow',
      contentLangKey: 'welcome_to_loubiairways_trunkshow',
      nextId: 2
    },
    {
      id: 2,
      content: 'Please let us know how we can help you.',
      contentLangKey: 'please_let_us_know_how_we_can_help_you',
      options: [
        {
          content: 'BOOK A VIRTUAL WALKTHROUGH',
          contentLangKey: 'book_a_virtual_walkthrough',
          inlinePopup: ChatPopupType.VISIT_APPOINTMENT,
          nextId: '',
          payload: {
            open: true,
            type: AppointmentType.VIRTUAL_WALKTHROUGH
          }
        },
        {
          content: 'DISCOVER THE COLLECTION',
          contentLangKey: 'discover_collection',
          inlinePopup: ChatPopupType.MAP,
          nextId: ''
        },
        {
          content: 'LEARN MORE ABOUT CHRISTIAN LOUBOUTIN',
          contentLangKey: 'learn_more_about_louboutin',
          inlinePopup: ChatPopupType.EXTERNAL_LINK,
          payload: {
            url: louboutinJpWebsite
          },
          nextId: ''
        },
        {
          content: 'RESERVE YOUR ITEMS',
          contentLangKey: 'reserve_items',
          nextId: 'Catalogs'
        },
        {
          content: 'CHAT WITH A SALES ADVISOR',
          contentLangKey: 'chat_with_sales_advisor',
          nextId: '',
          payload: {
            connectToAgent: true
          }
        }
      ]
    },
    {
      id: 'Catalogs',
      options: [
        {
          content: 'MEN',
          contentLangKey: 'MEN',
          inlinePopup: ChatPopupType.CUSTOM_ACTION,
          nextId: '',
          payload: actionStartFetchingCatalog({ gender: 'men' })
        },
        {
          content: 'WOMEN',
          contentLangKey: 'WOMEN',
          inlinePopup: ChatPopupType.CUSTOM_ACTION,
          payload: actionStartFetchingCatalog({ gender: 'women' }),
          nextId: ''
        },
        {
          content: 'BEAUTY',
          contentLangKey: 'BEAUTY',
          inlinePopup: ChatPopupType.CUSTOM_ACTION,
          payload: actionStartFetchingCatalog({ type: 'beauty' }),
          nextId: ''
        }
      ]
    }
  ],
  chatbotAvatarUrl:
    'https://inspify-panos.s3-ap-southeast-1.amazonaws.com/advisor_hub/476bd411-03e9-4e6f-8c8b-8c51d1e0f5e1/logo_light_200.png'
};
