import { format } from 'date-fns';
import React from 'react';
import AppointmentFormChatBubble from '../../../components/Chat/AppointmentFormChatBubble';
import MeetingLinkChatBubble from '../../../components/Chat/MeetingLinkChatBubble';
import PlainTextChatBubble from '../../../components/Chat/PlainTextChatBubble';
import ProductChatBubble from '../../../components/Chat/ProductChatBubble';
import ProductCompareBubble from '../../../components/Chat/ProductCompareBubble';
import ProductIndicationBubble from '../../../components/Chat/ProductIndicationBubble';
import ProductPersonalizeBubble from '../../../components/Chat/ProductPersonalizeBubble';
import StoryChatBubble from '../../../components/Chat/StoryChatBubble';
import {
  ChatMessageType,
  IChatMessageBody
} from '../../../components/ChatConnectionContext';
import { ClientChatContext } from '../../../components/VirtualBoutique/CustomComponent/LouboutinJpCustomComponent/LiveChatPanel/ClientChatContext';
import { ProductType } from '../../../interfaces';
import { HubContext } from '../HubContext';

const ChatBubbleContainer = ({
  messageBody,
  left,
  message,
  messageString,
  onClickProduct,
  onClickStory,
  openProductCompare,
  openProductPersonalize,
  clientSide = false,
  onImageLoaded,
  isAnotherSA
}: {
  messageBody: IChatMessageBody;
  messageString: string;
  left: boolean;
  message: any;
  onClickProduct: (productId: string) => void;
  onClickStory: (storyId: string) => void;
  openProductCompare?: (productIds: string, compareType: ProductType) => void;
  openProductPersonalize?: (
    productId: string,
    strap: string,
    buckle: string
  ) => void;
  clientSide?: boolean;
  isAnotherSA?: boolean;
  onImageLoaded?: () => void;
}) => {
  const timestamp = messageBody?.timestamp || message?.createdAt || null;
  let chatContent = <PlainTextChatBubble left={left} text={messageString} />;
  const Context: any = clientSide ? ClientChatContext : HubContext;
  const { color } = React.useContext<any>(Context);
  
  if (messageBody) {
    switch (messageBody?.type) {
      case ChatMessageType.PLAIN_TEXT:
        chatContent = (
          <PlainTextChatBubble left={left} text={messageBody?.content} />
        );
        break;
      case ChatMessageType.PRODUCT:
        chatContent = (
          <ProductChatBubble
            left={left}
            id={messageBody?.content}
            onClickProduct={onClickProduct}
            onImageLoaded={onImageLoaded}
          />
        );
        break;
      case ChatMessageType.PRODUCT_COMPARE: {
        const [productType, joinedProductIds] = (
          messageBody?.content || ''
        ).split('::'); // FIXME: Hack, need to change type of `content`
        chatContent = (
          <ProductCompareBubble
            left={left}
            products={joinedProductIds?.split(',')}
            onClick={() => {
              openProductCompare(joinedProductIds, productType as ProductType);
            }}
            onImageLoaded={onImageLoaded}
          />
        );
        break;
      }
      case ChatMessageType.PRODUCT_PERSONALIZE: {
        const [productId, brandId, strap, buckle] = (
          messageBody?.content || ''
        ).split('::'); // FIXME: Hack, need to change type of `content`
        chatContent = (
          <ProductPersonalizeBubble
            left={left}
            brandId={brandId}
            productId={productId}
            strap={strap}
            buckle={buckle !== 'undefined' ? buckle : undefined}
            onImageLoaded={onImageLoaded}
            onClick={() => {
              openProductPersonalize(productId, strap, buckle);
            }}
          />
        );
        break;
      }
      case ChatMessageType.STORY:
        chatContent = (
          <StoryChatBubble
            left={left}
            id={messageBody?.content}
            onClickStory={onClickStory}
            onImageLoaded={onImageLoaded}
          />
        );
        break;
      case ChatMessageType.MEETING_LINK:
        chatContent = (
          <MeetingLinkChatBubble
            left={left}
            link={messageBody?.content}
            disabled={!clientSide}
            color={color}
          />
        );
        break;
      case ChatMessageType.APPOINTMENT_FORM:
        chatContent = (
          <AppointmentFormChatBubble
            left={left}
            link={messageBody?.content}
            color={color}
          />
        );
        break;
      case ChatMessageType.INDICATOR:
        chatContent = (
          <ProductIndicationBubble
            left={left}
            messageContent={messageBody?.content}
            onClickProduct={onClickProduct}
            onImageLoaded={onImageLoaded}
          />
        );
        break;
      default:
        chatContent = <PlainTextChatBubble left={left} text={messageString} />;
        break;
    }
  }

  if (
    messageBody?.type === ChatMessageType.USER_UPDATED ||
    messageBody?.type === ChatMessageType.USER_ONLINE ||
    messageBody?.type === ChatMessageType.JOINED_CHAT
  ) {
    return null;
  }

  return (
    <div
      className={`message-item ${left ? 'left' : ''} ${
        isAnotherSA ? 'anotherSA' : ''
      }`}
    >
      {chatContent}
      {timestamp && (
        <div className={`message-timestamp ${left && `left`}`}>
          {left ? `Received` : `Sent`}{' '}
          {format(new Date(timestamp), 'd LLL uuuu, hh:mm aaa')}
        </div>
      )}
    </div>
  );
};

export default ChatBubbleContainer;
