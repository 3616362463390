import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { logEvent } from '../../analytics';
import { getProductsByIds } from '../../clientSideServices/products';
import { useTranslation } from '../../i18n';
import { IProduct } from '../../interfaces';
import { DID_CLICK_ON_CHAT_MESSAGE } from '../../utils/constants';
import { ChatMessageType } from '../ChatConnectionContext';

const ProductChatBubble = ({
  left,
  id,
  onClickProduct,
  onImageLoaded
}: {
  left: boolean;
  id: string;
  onClickProduct: (productId: string) => void;
  onImageLoaded?: () => void;
}) => {
  const [product, setProduct] = useState<IProduct>();
  const { i18n } = useTranslation();
  useEffect(() => {
    let isMounted = true;
    getProductsByIds([id])
      .then((result) => {
        if (isMounted) {
          setProduct(get(result, 'data[0]'));
        }
      })
      .catch((e) => {
        console.error(e);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  if (!product) {
    return <span>Loading product...</span>;
  }

  let currentLanguage = i18n.language ? i18n.language.toUpperCase() : 'EN';
  if (currentLanguage === 'JA') {
    currentLanguage = 'JP';
  }

  return (
    <div
      className={`message-content ${left && `left`}`}
      onClick={() => {
        onClickProduct(product.id);
        logEvent(DID_CLICK_ON_CHAT_MESSAGE, DID_CLICK_ON_CHAT_MESSAGE, {
          type: ChatMessageType.PRODUCT,
          id: product.id
        });
      }}
    >
      <div className="item-wrapper">
        <div className="item">
          <div className="product-image">
            <img src={product.previewImageUrl} onLoad={onImageLoaded} />
          </div>
          <div className="product-info">
            <div className="code">{product?.modelCode}</div>
            <h3 className="product-title">{product.modelName}</h3>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .message-content {
            width: 220px;
            cursor: pointer;
            padding: 0;
            overflow: hidden;
            border-radius: 10px;
            background: #e4e4e4;
          }
          .item-wrapper {
          }
          .item {
            position: relative;
          }
          .product-info {
            width: 100%;
            padding: 16px;
            background: #f2f2f2;
            text-align: center;
          }
          .code {
            font-size: 10px;
            line-height: 12px;
            color: #666363;
          }
          .product-title {
            font-weight: 600;
            text-align: center;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .product-image {
            margin: 10px auto;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .product-image img {
            height: 190px;
            width: auto;
          }

          @media (max-width: 480px) {
            .message-content {
              width: 180px;
            }

            .product-image img {
              height: 140px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ProductChatBubble;
