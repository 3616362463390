import { logEvent } from '../../analytics';
import { DID_CLICK_POWERED_BY_INSPIFY } from '../../utils/constants';

interface Props {
  color?: 'dark' | 'light';
  position?: 'right bottom' | 'center center' | 'left bottom';
  style?: 'normal' | 'shadow';
}
const PoweredByInspify = ({
  color = 'dark',
  position = 'right bottom',
  style = 'normal'
}: Props) => {
  const getLogo = () => {
    if (style === 'shadow') {
      return '/asset/powered_by_inspify_shadow.png';
    }
    if (color === 'dark' && position === 'right bottom')
      return '/asset/powered_by_inspify_dark.png';
    if (color === 'dark' && position === 'center center')
      return '/asset/powered_by_inspify_dark_center.png';
    if (color === 'light' && position === 'right bottom')
      return '/asset/powered_by_inspify_light_center.png';
    if (color === 'light' && position === 'center center')
      return '/asset/powered_by_inspify_light_center.png';
  };

  return (
    <>
      <a
        className="powered-by-inspify"
        onClick={() => logEvent(DID_CLICK_POWERED_BY_INSPIFY)}
        target="_blank"
        href="https://www.inspify.com"
      ></a>
      <style jsx>
        {`
          .powered-by-inspify {
            cursor: pointer;
            display: block;
            background: url('${getLogo()}') no-repeat ${position};
            background-size: contain;
            height: ${style === 'shadow' ? '30px !important' : '50px'};
            margin-top: 20px;
          }
        `}
      </style>
    </>
  );
};

export default PoweredByInspify;
