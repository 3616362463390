import { logEvent } from '../../analytics';
import { staticBaseUrl } from '../../config';
import { DID_CLICK_ON_CHAT_MESSAGE } from '../../utils/constants';
import { ChatMessageType } from '../ChatConnectionContext';

const ProductCompareBubble = ({
  left,
  products,
  onClick,
  onImageLoaded
}: {
  left: boolean;
  products: string[];
  onClick: () => void;
  onImageLoaded?: () => void;
}) => {
  const compareIcon = (() => {
    let fileName = 'two_watches_comparison.svg';
    if (products.length === 3) {
      fileName = 'three_watches_comparison.svg';
    } else if (products.length === 4) {
      fileName = 'four_watches_comparison.svg';
    }
    return `${staticBaseUrl}/connect/assets/${fileName}`;
  })();
  return (
    <div
      className={`message-content ${left && `left`}`}
      onClick={() => {
        onClick();
        logEvent(DID_CLICK_ON_CHAT_MESSAGE, DID_CLICK_ON_CHAT_MESSAGE, {
          type: ChatMessageType.PRODUCT_COMPARE,
          products
        });
      }}
    >
      <div className="item-wrapper">
        <img src={compareIcon} alt="Compare Icon" onLoad={onImageLoaded} />
        <h3 className="title">VIEW COMPARISON</h3>
      </div>
      <style jsx>
        {`
          .message-content {
            width: 220px;
            border-radius: 10px;
            cursor: pointer;
            overflow: hidden;
            background: #fff;
            border: 1px solid #e2e2e2;
            padding: 0;
          }

          .item-wrapper {
            display: flex;
            flex-direction: column;
          }

          img {
            margin: 20px auto;
            width: 100px;
            display: block;
          }

          .title {
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            background: #f0f0f0;
            margin: 0;
            padding: 16px;
          }

          @media (max-width: 480px) {
            .message-content {
              width: 180px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ProductCompareBubble;
