import {
  ChatMessageType,
  IChatMessage,
  IChatMessageBody,
  IndicatorMessageType
} from '../components/ChatConnectionContext';
import { isValidDate } from './clock';
import { dec } from './crypt';
import { isJsonString } from './string';

export const getChatMessageTimeStamp = (message: IChatMessage) => {
  let date;
  const messageBody = decryptMessage(message?.messageBody);

  if (messageBody?.timestamp) {
    date = new Date(messageBody?.timestamp);
  } else {
    date = new Date(message?.createdAt);
  }

  return isValidDate(date) ? date : new Date();
};

export const sortConversationChronologically = (conversations: any[]) => {
  return conversations.sort((conversationOne, conversationTwo) => {
    const conversationOneLastMessageTime: Date = getChatMessageTimeStamp(
      conversationOne?.lastMessage
    );
    const conversationTwoLastMessageTime: Date = getChatMessageTimeStamp(
      conversationTwo?.lastMessage
    );
    return (
      conversationTwoLastMessageTime.getTime() -
      conversationOneLastMessageTime.getTime()
    );
  });
};

export const sortConversationRequestChronologically = (
  conversations: any[]
) => {
  return conversations.sort((conversationOne, conversationTwo) => {
    const conversationOneMessageTime: Date =
      getChatMessageTimeStamp(conversationOne);
    const conversationTwoMessageTime: Date =
      getChatMessageTimeStamp(conversationTwo);

    return (
      conversationTwoMessageTime.getTime() -
      conversationOneMessageTime.getTime()
    );
  });
};

export const decryptMessage = (messageString: string) => {
  const messageBody = isJsonString(messageString)
    ? JSON.parse(messageString)
    : null;

  if (messageBody?.id && messageBody?.content) {
    return JSON.parse(dec(messageBody?.content, messageBody?.id));
  }

  if (messageBody) {
    return messageBody;
  }

  return null;
};

export const getMessageDisplayText = (messageBody?: IChatMessageBody) => {
  switch (messageBody?.type) {
    case ChatMessageType.INDICATOR:
      return getIndicationPreviewMessage(messageBody)
    case ChatMessageType.PLAIN_TEXT:
      return messageBody?.content;
    case ChatMessageType.MEETING_LINK:
      return `Meeting link sent to client`;
    case ChatMessageType.APPOINTMENT_FORM:
      return `Appointment form sent to client`;
    case ChatMessageType.PRODUCT:
      return `Shared a product`;
    case ChatMessageType.PRODUCT_COMPARE:
      return `Shared product comparison`;
    case ChatMessageType.PRODUCT_PERSONALIZE:
      return `Shared product personalization`;
    case ChatMessageType.STORY:
      return `Shared a story`;
    case ChatMessageType.USER_UPDATED:
    case ChatMessageType.USER_ONLINE:
      return `Updated user details`;
    default:
      return '';
  }
};
export const getIndicationFromIType = (iType: IndicatorMessageType) => {
  switch (iType) {
    case IndicatorMessageType.ADD_CART:
      return 'THIS ITEM HAS BEEN ADDED TO CART';
    case IndicatorMessageType.ADD_WISHLIST:
      return 'THIS ITEM HAS BEEN ADDED TO WISHLIST';
    case IndicatorMessageType.REMOVE_WISHLIST:
      return 'THIS ITEM HAS BEEN REMOVED FROM WISHLIST';
    default:
      return '';
  }
};

export const getIndicationPreviewMessage = (messageBody: IChatMessageBody) => {
  try {
    const { iType } = JSON.parse(messageBody?.content);
    switch (iType) {
      case IndicatorMessageType.ADD_CART:
        return 'Has added a product to cart';
      case IndicatorMessageType.ADD_WISHLIST:
        return 'Has added a product to wishlist';
      case IndicatorMessageType.REMOVE_WISHLIST:
        return 'Has removed a product from wishlist';
      default:
        return '';
    }
  } catch (error) {
    return '';
  }
}

export const getIndicationErrorMessage = (iType: IndicatorMessageType) => {
  switch (iType) {
    case IndicatorMessageType.ADD_CART:
      return 'The product added to cart is currently not available';
    case IndicatorMessageType.ADD_WISHLIST:
      return 'The product added to wishlist is currently not available';
    case IndicatorMessageType.REMOVE_WISHLIST:
      return 'The product removed from wishlist is currently not available';
    default:
      return 'Product is not available';
  }
}

export const mapConversationRequests = (conversations: any[]) => {
  return conversations?.map(con => {
    const { lastMessage } = con;
    return {
      conversationId: con.conversationId,
      messageBody: lastMessage?.messageBody,
      messageId: lastMessage?.id,
      senderId: lastMessage?.senderId
    }
  })
}