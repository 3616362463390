import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BsCameraVideo } from 'react-icons/bs';
import { logEvent } from '../../analytics';
import { getInspirationsByIds } from '../../clientSideServices/inspirations';
import { IInspiration } from '../../interfaces';
import { DID_CLICK_ON_CHAT_MESSAGE } from '../../utils/constants';
import { ChatMessageType } from '../ChatConnectionContext';

const StoryChatBubble = ({
  left,
  id,
  onClickStory,
  onImageLoaded
}: {
  left: boolean;
  id: string;
  onClickStory: (storyId: string) => void;
  onImageLoaded?: () => void;
}) => {
  const [story, setStory] = useState<IInspiration>();

  useEffect(() => {
    let isMounted = true;
    getInspirationsByIds([id])
      .then((result) => {
        if (isMounted) {
          setStory(get(result, 'data[0]'));
        }
      })
      .catch((e) => {
        console.error(e);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  if (!story) {
    return <span>Loading story...</span>;
  }

  const onClick = () => {
    onClickStory(story.id);
    logEvent(DID_CLICK_ON_CHAT_MESSAGE, DID_CLICK_ON_CHAT_MESSAGE, {
      type: ChatMessageType.STORY,
      id: story.id
    });
  };

  const getStoryIcon = () =>
    story?.type === 'video' && <BsCameraVideo size={20} />;

  return (
    <div className={`message-content ${left && `left`}`} onClick={onClick}>
      <div className="item">
        <div className="story-info">
          <h4 className="story-title">{story.title}</h4>
        </div>
        <div className="story-image">
          <img src={story.flipImageUrl} onLoad={onImageLoaded} />
        </div>
        <div className="story-icon">{getStoryIcon()}</div>
      </div>
      <style jsx>
        {`
          .message-content {
            cursor: pointer;
            max-width: 220px;
            padding: 10px;
            overflow: hidden;
            border-radius: 10px;
            border: 1px solid #e2e2e2;
          }
          .item {
            margin: -10px;
            position: relative;
            margin-bottom: -20px;
          }
          .story-info {
            position: absolute;
            width: 100%;
            bottom: 10px;
            background: rgba(242, 242, 242, 0.9);
            text-align: left;
            padding: 8px 10px;
            min-height: 60px;
          }
          .story-title {
            font-size: 10px;
            color: #666363;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-align: justify;
            margin: 0;
          }
          .story-image {
            max-width: 100%;
          }
          .story-image img {
            max-width: 100%;
            max-height: 315px;
          }
          .story-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            color: #f0f0f0;
          }
          .story-icon:hover {
            color: black;
          }

          @media (max-width: 480px) {
            .message-content {
              max-width: 180px;
            }

            .story-image img {
              max-height: 100%;
            }
          }
        `}
      </style>
    </div>
  );
};

export default StoryChatBubble;
