import React from 'react';
import { ThemeColor } from '../../advisorHub/components/BrandStyle';
import { logEvent } from '../../analytics';
import { useTranslation } from '../../i18n';
import { DID_CLICK_ON_CHAT_MESSAGE } from '../../utils/constants';
import { ChatMessageType } from '../ChatConnectionContext';

const MeetingLinkChatBubble = ({
  left,
  link,
  disabled,
  color
}: {
  left: boolean;
  link: string;
  disabled?: boolean;
  color: ThemeColor;
}) => {
  const { t } = useTranslation();
  return (
    <div className={`message-content ${left && `left`}`}>
      {t('invited_to_private_session')}
      <a
        className={`btn join-btn ${disabled && `disabled`}`}
        href={link}
        target="_blank"
        onClick={() => {
          logEvent(DID_CLICK_ON_CHAT_MESSAGE, DID_CLICK_ON_CHAT_MESSAGE, {
            type: ChatMessageType.MEETING_LINK,
            id: link
          });
        }}
      >
        Join
      </a>
      <style jsx>
        {`
          .join-btn {
            display: block;
            margin-top: 10px;
            color: white;
            background: ${color.btnPrimary};
          }
          .join-btn.disabled {
            pointer-events: none;
            cursor: default;
          }
        `}
      </style>
    </div>
  );
};

export default MeetingLinkChatBubble;
