import { logEvent } from '../../analytics';
import { DID_CLICK_ON_CHAT_MESSAGE } from '../../utils/constants';
import { ChatMessageType } from '../ChatConnectionContext';

const ProductPersonalizeBubble = ({
  left,
  brandId,
  productId,
  strap,
  buckle,
  onClick,
  onImageLoaded
}: {
  left: boolean;
  brandId: string;
  productId: string;
  strap: string;
  buckle?: string;
  onClick: () => void;
  onImageLoaded?: () => void;
}) => {
  const dialImage = `https://products.inspify.io/${brandId}/${productId}/dial/${productId}_dial.png`;
  const topStrapImage = `https://products.inspify.io/${brandId}/${strap}/straps/${strap}_top.png`;
  const buckleImage = `https://products.inspify.io/${brandId}/${buckle}/buckles/${buckle}_small.png`;
  return (
    <div
      className={`message-content personalize ${left && `left`}`}
      onClick={() => {
        onClick();
        logEvent(DID_CLICK_ON_CHAT_MESSAGE, DID_CLICK_ON_CHAT_MESSAGE, {
          type: ChatMessageType.PRODUCT_PERSONALIZE
        });
      }}
    >
      <div className="item-wrapper">
        {productId && strap && (
          <div className="product-images">
            <div className="dial">
              <img src={dialImage} alt="Dial" onLoad={onImageLoaded} />
            </div>
            <div className="strap-and-buckle">
              {buckle && (
                <img src={buckleImage} className="buckle" alt="Buckle" />
              )}
              <img src={topStrapImage} className="strap" alt="Strap" />
            </div>
          </div>
        )}
        <h3 className="title">VIEW YOUR WATCH</h3>
      </div>
      <style jsx>
        {`
          :global(.footer) .message-content.personalize {
            width: 220px;
          }
          .message-content {
            border-radius: 1rem;
            cursor: pointer;
            overflow: hidden;
            background: #fff;
            border: 1px solid #e2e2e2;
            padding: 0;
          }

          .product-images {
            display: flex;
            width: 220px;
            justify-content: center;
            position: relative;
            top: ${buckle ? '0' : '-15px'};
          }

          .product-images img {
            object-fit: cover;
            width: 200px;
          }

          .strap-and-buckle {
            position: relative;
          }

          .dial img {
            width: 200px;
            position: relative;
            left: 55px;
            top: 15px;
            transform: scale(1.2);
          }

          .product-images .buckle {
            position: absolute;
            width: 68px;
            top: 3px;
            left: 16px;
          }

          .product-images .strap {
            position: relative;
            right: 40px;
            top: ${buckle ? '30px' : '20px'};
            width: 180px;
          }

          .item-wrapper {
            display: flex;
            flex-direction: column;
          }

          .title {
            font-size: 14px;
            text-align: center;
            background: #f0f0f0;
            margin: 0;
            padding: 15px;
          }
        `}
      </style>
    </div>
  );
};

export default ProductPersonalizeBubble;
