import { first, get, last } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getThemeColor,
  ThemeColor
} from '../../../../../advisorHub/components/BrandStyle';
import { logEvent } from '../../../../../analytics';
import { useTranslation } from '../../../../../i18n';
import {
  ChatMessageConfig,
  ChatMessageOption,
  ChatPopupType
} from '../../../../../interfaces';
import {
  actionAppointmentPopup,
  actionShowContactPopup
} from '../../../../../redux/actions';
import { actionUpdateLoubiAirwaysPlanePlan } from '../../../../../redux/customActions/loubiAirways';
import {
  DID_OPEN_APPOINTMENT_POPUP,
  DID_OPEN_CONTACT_POPUP,
  DID_OPEN_EXTERNAL_LINK,
  DID_OPEN_VB_CONTENT_POPUP,
  DID_SHOW_CHAT_MESSAGE
} from '../../../../../utils/constants';
import { enc } from '../../../../../utils/crypt';
import { getIdentityId } from '../../../../../utils/identity';
import { generateV4UUID } from '../../../../../utils/identityGenerator';
import { getPopupAction } from '../../../../../utils/pageView';
import {
  ChatConnectionContext,
  ChatMessageType,
  ChatUserType,
  IChatMessage,
  IChatMessageBody,
  WSAcknowledgementStatusType,
  WSMessageType
} from '../../../../ChatConnectionContext';
import { useStateRef } from '../../../../hooks/common';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import liveChatConfig from './liveChatConfig';

interface ChatMessage extends ChatMessageConfig {
  position: 'left' | 'right';
}

export const ClientChatContext =
  React.createContext<{
    messages: any[];
    conversationId: string;
    sendMessage: (messagePayload) => void;
    handleOption: (options) => void;
    openVBPopup: (payload) => void;
    availableAgents: any[];
    hasMore: any | null;
    loadMoreMessages: () => void;
    chatIndex: number;
    agentId: string;
    color: ThemeColor;
  }>(undefined);

const messageListenerId = generateV4UUID();

const ClientChatContextContainer = ({
  brandId,
  children
}: {
  brandId: string;
  children: React.ReactNode;
}) => {
  const {
    connected,
    connect,
    disconnect,
    addMessageListener,
    removeMessageListener,
    addOnConnectAction,
    removeOnConnectAction,
    checkChatStoreAvailability,
    fetchConversations,
    initiateConversation,
    fetchChatMessagesByConversationId,
    onSendMessage,
    joinChatSession
    // isOnline,
    // setIsOnline
  } = React.useContext(ChatConnectionContext);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const [messages, setMessages] = useState([]);
  const [availableAgents, setAvailableAgents] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const loubiAirwaysState = useLoubiAirwaysState();
  const planState = loubiAirwaysState?.planePlan || {};
  const storeId = loubiAirwaysState?.invite?.storeId;
  const [hasMore, setHasMore] = useState(undefined);
  const [chatIndex, setChatIndex, chatIndexRef] = useStateRef(3);
  const [agentId, setAgentId] = useState('');
  const color = getThemeColor(brandId);
  const receiveAck = (data) => {
    if (data.status === WSAcknowledgementStatusType.SET_AVAILABILITY) {
      // setIsOnline(!isOnline);
      // fetchConversations(getIdentityId());
    }

    if (data.status === WSAcknowledgementStatusType.CONVERSATION_INITIATED) {
      setConversationId(data.conversationId);
    }
  };

  const onMessageListener = (event) => {
    const parsedData = JSON.parse(event.data);

    if (parsedData?.messageType === WSMessageType.ACK) {
      receiveAck(parsedData);
      return;
    }

    // Incomming message
    if (parsedData?.messageBody && parsedData?.senderId !== getIdentityId()) {
      _setAgentId(parsedData?.senderId);
      const dataMessageBody = parsedData?.messageBody;
      showMessage({
        id: generateV4UUID(),
        content: dataMessageBody,
        position: 'left'
      });
      return;
    }

    // List of conversations
    if (parsedData?.conversations) {
      if (parsedData?.conversations.length) {
        const lastConversationId = get(
          last(parsedData?.conversations),
          'conversationId'
        );
        setConversationId(lastConversationId);
        fetchChatMessagesByConversationId(lastConversationId);
      } else {
        // new conversation
        initiateConversation(storeId, getIdentityId(), 'userName');
      }

      return;
    }

    // List of messages
    const _messages = parsedData?.messages
      ?.map((_item) => {
        if (_item.senderId !== getIdentityId()) _setAgentId(_item.senderId);
        return {
          id: _item.id,
          content: _item.messageBody,
          position: _item.senderId === getIdentityId() ? 'right' : 'left'
        };
      })
      .reverse();
    if (_messages) {
      setMessages((messages) => [
        ...messages.slice(0, chatIndexRef.current),
        ..._messages,
        ...messages.slice(chatIndexRef.current)
      ]);

      //check has more messages
      const _hasMore = parsedData?.resultPage?.lastEvaluatedKey;
      setHasMore(_hasMore);
    }

    // List of agent online
    const _availableAgents = parsedData?.availableAgents;
    if (_availableAgents) {
      setAvailableAgents(_availableAgents);
    }
    // UserAgent availability
    const _availability = parsedData?.availability;
    if (_availability) {
      checkChatStoreAvailability(storeId);
    }
  };

  const joinClientChatSession = () => {
    joinChatSession(getIdentityId(), storeId, ChatUserType.CLIENT);
  };

  const connectToStore = () => {
    addMessageListener({
      id: messageListenerId,
      listener: onMessageListener
    });
    addOnConnectAction({
      id: messageListenerId,
      action: joinClientChatSession
    });
    connect();
  };

  const sendMessage = (messageText) => {
    const messageBody: IChatMessageBody = {
      content: messageText,
      type: ChatMessageType.PLAIN_TEXT,
      timestamp: new Date()
    };

    const encryptionId = generateV4UUID();
    const encrypted = {
      id: encryptionId,
      content: enc(JSON.stringify(messageBody), encryptionId)
    };

    const newMessage: IChatMessage = {
      messageBody: JSON.stringify(encrypted),
      requestId: generateV4UUID(),
      userId: getIdentityId(),
      conversationId
    };
    onSendMessage(newMessage);
    setMessages((oldMessage) => [
      ...oldMessage,
      {
        id: generateV4UUID(),
        content: newMessage.messageBody,
        position: 'right'
      }
    ]);
  };

  const showMessage = (message: ChatMessage) => {
    setMessages((messages) => {
      if (message?.contentLangKey === 'chat_with_sales_advisor') {
        setChatIndex(messages.length + 1);
      }
      return [...messages, message];
    });
    logEvent(DID_SHOW_CHAT_MESSAGE, DID_SHOW_CHAT_MESSAGE, message);
  };

  const showNextMessage = (id: string | number, delayTime: number) => {
    const nextMessage = liveChatConfig.messages.find((m) => m.id === id);
    if (nextMessage) {
      setTimeout(() => {
        showMessage({ ...nextMessage, position: 'left' });
      }, delayTime);
    }
  };

  const handleOption = (option: ChatMessageOption) => {
    showMessage({
      id: generateV4UUID(),
      content: option.content,
      contentLangKey: option.contentLangKey,
      position: 'right',
      nextId: option.nextId,
      popupId: option.popupId,
      popupType: option.popupType,
      nextDelayInMS: option.nextDelayInMS
    });
    if (option.inlinePopup) {
      openInlinePopup(option.inlinePopup, option?.payload);
    }
    if (option?.payload?.connectToAgent) {
      fetchConversations(getIdentityId());
    }
  };

  const openInlinePopup = (popupType: ChatPopupType, payload?: any) => {
    switch (popupType) {
      case ChatPopupType.VISIT_APPOINTMENT:
        openAppointmentForm(payload);
        break;
      case ChatPopupType.VB_POPUP_CONTENT:
        openVBPopup(payload);
        break;
      case ChatPopupType.CONTACT:
        openBoutiqueContactPopup();
        break;
      case ChatPopupType.EXTERNAL_LINK:
        openExternalLink(payload);
        break;
      case ChatPopupType.MAP:
        dispatch(
          actionUpdateLoubiAirwaysPlanePlan({
            ...planState,
            selectedSpot: undefined,
            open: !planState?.open
          })
        );
        break;
      case ChatPopupType.CUSTOM_ACTION:
        dispatch(payload);
        break;
      default:
        break;
    }
  };

  const loadMoreMessages = () => {
    if (hasMore) fetchChatMessagesByConversationId(conversationId, 25, hasMore);
  };

  const openVBPopup = (payload: any) => {
    if (payload?.action) {
      dispatch(payload.action);
    } else {
      const pageViewType = payload?.type;
      const idByLang = payload?.id || {};
      const id = idByLang[i18n.language] || payload?.id;
      const action = getPopupAction(
        `${location.protocol}//${location.host}`,
        pageViewType,
        id,
        payload?.brandId,
        storeId
      );
      if (action) {
        dispatch(action);
      }
    }
    logEvent(DID_OPEN_VB_CONTENT_POPUP, DID_OPEN_VB_CONTENT_POPUP, payload);
  };

  const openBoutiqueContactPopup = () => {
    setTimeout(() => dispatch(actionShowContactPopup(true)), 1000);
    logEvent(DID_OPEN_CONTACT_POPUP, DID_OPEN_CONTACT_POPUP);
  };

  const openAppointmentForm = (payload: any) => {
    dispatch(actionAppointmentPopup(payload));
    logEvent(DID_OPEN_APPOINTMENT_POPUP, '', payload);
  };

  const openExternalLink = (payload: any) => {
    const url = (payload || {}).url;
    if (url) {
      window.open(url, '_blank');
      logEvent(DID_OPEN_EXTERNAL_LINK, DID_OPEN_EXTERNAL_LINK, payload);
    }
  };

  React.useEffect(() => {
    if (connected) {
      checkChatStoreAvailability(storeId);
    }
  }, [connected]);

  React.useEffect(() => {
    const lastMessage = last(messages);
    if (lastMessage?.nextId) {
      showNextMessage(lastMessage.nextId, lastMessage?.nextDelayInMS || 1000);
    }
  }, [messages]);

  React.useEffect(() => {
    if (storeId && !connected) {
      connectToStore();
    }
  }, [storeId]);

  React.useEffect(() => {
    const firstMessage = first(liveChatConfig.messages);
    if (firstMessage) {
      setTimeout(() => {
        showMessage({
          ...firstMessage,
          position: 'left'
        });
      }, 1000);
    }
    return () => {
      if (connected) {
        removeMessageListener(messageListenerId);
        removeOnConnectAction(messageListenerId);
        disconnect();
      }
    };
  }, []);

  const _setAgentId = (id) => {
    if (id === agentId) return;
    setAgentId(id);
  };

  return (
    <ClientChatContext.Provider
      value={{
        messages,
        conversationId,
        sendMessage,
        handleOption,
        openVBPopup,
        availableAgents,
        hasMore,
        loadMoreMessages,
        chatIndex,
        agentId,
        color
      }}
    >
      {children}
    </ClientChatContext.Provider>
  );
};

export default ClientChatContextContainer;
