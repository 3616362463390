import React from 'react';
import { ThemeColor } from '../../advisorHub/components/BrandStyle';
import { logEvent } from '../../analytics';
import { DID_CLICK_ON_CHAT_MESSAGE } from '../../utils/constants';
import { ChatMessageType } from '../ChatConnectionContext';

const AppointmentFormChatBubble = ({
  left,
  link,
  color
}: {
  left: boolean;
  link: string;
  color: ThemeColor;
}) => {
  return (
    <div className={`message-content ${left && `left`}`}>
      You have been invited to book an appointment.
      <a
        className={`btn join-btn disabled`}
        href={link}
        target="_blank"
        onClick={() => {
          logEvent(DID_CLICK_ON_CHAT_MESSAGE, DID_CLICK_ON_CHAT_MESSAGE, {
            type: ChatMessageType.APPOINTMENT_FORM
          });
        }}
      >
        Book Appointment
      </a>
      <style jsx>
        {`
          .join-btn {
            display: block;
            margin-top: 10px;
            color: white;
            background: ${color.btnPrimary};
            font-size: 14px;
          }
          .join-btn.disabled {
            pointer-events: none;
            cursor: default;
          }
        `}
      </style>
    </div>
  );
};

export default AppointmentFormChatBubble;
